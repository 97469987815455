import { Button, Col, Row, Card, Input, Select, DatePicker, Pagination, Space, Modal, message, Spin } from "antd"

import { useEffect, useState } from "react"
import apiRequest from "../../Utils/apiRequest"
import "./signaturesControl.scss"
import getDateRanges from "../../Utils/getDateRanges";
import _ from "lodash";
import { CaretUpOutlined, CaretDownOutlined, MinusOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons';


export default function SignaturesControl() {


    const { RangePicker } = DatePicker;

    const [signatures, setSignatures] = useState([])
    const [signaturesOriginal, setSignaturesOriginal] = useState(null);
    const [paginaActual, setPaginaActual] = useState(1);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(10)
    const [totalRegistros, setTotalRegistros] = useState(1)

    const [isLoading, setIsLoading] = useState(false);

    const [searchColaborador, setSearchColaborador] = useState(null);
    const [searchIp, setSearchIp] = useState(null);
    const [searchIntentos, setSearchIntentos] = useState(null);
    const [searchUltIntento, setSearchUltIntento] = useState(null);

    const [confirmModal, setConfirmModal] = useState(false);
    const [modalColaborador, setModalColaborador] = useState("");

    const [orden, setOrden] = useState("")
    const [sort, setSort] = useState({
        column : "",
        order : ""
    })
    const [colaboradores, setColaboradores] = useState(null);

    useEffect(() => {
        (async () => {
            // Por defecto cogemos los datos de hoy
            let signaturesResp = await apiRequest("get", "/signaturesControl?pagina=" + paginaActual + "&registros=" + registrosPorPagina, {}, true);
            if (signaturesResp) {

                setTotalRegistros(signaturesResp.totalRegistros)
                setSignatures(signaturesResp.signatureControl)
                setSignaturesOriginal(signaturesResp.signatureControl)
            }

            let getColaboradores = await apiRequest("get","/colaboradores",{},true);

            if(getColaboradores){
                setColaboradores(getColaboradores.colaboradores);
            }

        })()
    },[])


    const limpiarDatos = () => {
        setSearchColaborador(null)
        setSearchIntentos(null);
        setSearchIp(null)
        setSearchUltIntento(null);

    };

    const buscar = async () => {
        setIsLoading(true)
        let getQuery = []
        

        if(searchColaborador) getQuery.push("colaborador="+searchColaborador)
        if(searchIp) getQuery.push("ip="+searchIp)
        if(searchUltIntento && searchUltIntento.length > 0) {
            let fechaIni = searchUltIntento[0]?.format("YYYY-MM-DD");
            let fechaFin = searchUltIntento[1]?.format("YYYY-MM-DD");
            getQuery.push("fcdesde="+fechaIni+"&fchasta="+fechaFin)
        }
        if(searchIntentos) getQuery.push("intentos="+searchIntentos)
        
        getQuery.push("pagina="+paginaActual)
        getQuery.push("registros="+registrosPorPagina)

        let query = (getQuery.length > 0 ) ?  "?" + getQuery.join("&") : "";

        let signaturesResp = await apiRequest("get", "/signaturesControl" + query, {}, true);
        if(signaturesResp){
            setTotalRegistros(signaturesResp.totalRegistros)
            setSignatures(signaturesResp.signatureControl)
            setSignaturesOriginal(signaturesResp.signatureControl)
        }
        setIsLoading(false)
    } 

    useEffect( () => {
        buscar();
    }, [paginaActual,registrosPorPagina]);

    useEffect(() => {
        
        let listaActual = signaturesOriginal ? [...signaturesOriginal] : [];
        
        switch (sort.order) {
            case "ascend":
                listaActual = _.orderBy(listaActual, [sort.column],['asc'])
                break;
            case "descend":
                listaActual = _.orderBy(listaActual, [sort.column],['desc'])        
                break;
        
            default:
                listaActual = signaturesOriginal;
                break;
        }
        
        setSignatures(listaActual)


    },[sort])


    const validarIp = async () => {
        
        const response = await apiRequest("post","/validarIp",{ _id : modalColaborador?._id },true);
        if( response.ok ) message.success("Validada correctamente");
        else message.error("Error");

        buscar();
        setConfirmModal(false);

    };


    const SortComponent = (props) => {
        
        let columna = props.sortColumn
        
        const ordenar = () => {
            if(orden === ""){   
                console.log( "sin orden" );
                setOrden("ascend")
                setSort({
                    column : columna,
                    order : "ascend"
                })
            } else if( orden === "ascend"){
                console.log( "ascendente" );
                setOrden("descend")
                setSort({
                    column : columna,
                    order : "descend"
                })
            } else if( orden === "descend"){
                console.log( "descendente" );
                setOrden("")
                setSort({
                    column : columna,
                    order : ""
                })
            }
            
        }
        
        let icon = <MinusOutlined />

        if(sort.column === columna) {
            switch (orden) {
                case "ascend":
                    icon = <CaretUpOutlined />
                    break;
            
                case "descend":
                    icon = <CaretDownOutlined />
                    break;
                default:
                    break;
            }
        }

        return (
            <div style={{display:"inline", cursor:"pointer", textAlign:"right", width:"100%"}} onClick={ordenar}>
                {icon}
            </div>
        )
    }

    const ModalConfirm = () => {

        return <Modal 
            visible={confirmModal}
            onOk= {()=>{
                validarIp();
            }}
            onCancel={ ()=>{
                setConfirmModal(false);
            } }
            >
                Estas seguro de querer validar la IP de {modalColaborador?.nombre_colaborador?.toUpperCase()}
            </Modal>

    }



    return (
        <>
            <div style={{ padding: "20px" }}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                    <Col flex="1 0 25%" className="column gutter-row">
                        <>
                            <Card title="Búsqueda" className="main-flatCard mb5" style={{ width: "100%", transition: "opacity 200ms" }} /* extra={<Button type="link" onClick={showList}><ArrowLeftOutlined /></Button>} */ >
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} >
                                    <Col className="w50 columna100">
                                        <Row>
                                            <Col md={4} xl={4}>Colaborador</Col>
                                            <Col>
                                                <Space direction="horizontal" style={{ marginBottom: "7px" }}>
                                                    <Select
                                                        value={searchColaborador}
                                                        showSearch={true}
                                                        style={{ width: "100%" }}
                                                        placeholder="input search text"
                                                        onChange={(ev) => {
                                                            setSearchColaborador(ev)
                                                        }}
                                                        allowClear
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        { colaboradores && colaboradores.map( _colaborador => {
                                                            
                                                            return <>
                                                                <Select.Option 
                                                                    value={_colaborador.id_usuario}
                                                                    key={_colaborador.id_usuario}
                                                                >
                                                                    {_colaborador.razon_social}
                                                                </Select.Option>
                                                                </>
                                                        }) }

                                                    </Select>
                                                </Space>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={4} xl={4}>Ip</Col>
                                            <Col>
                                                <Space direction="horizontal" style={{ marginBottom: "7px" }}>
                                                    <Input
                                                        value={searchIp}
                                                        style={{ width: "100%" }}
                                                        placeholder="input search text"
                                                        onChange={(ev) => {
                                                            setSearchIp(ev.target.value)
                                                        }}
                                                        allowClear
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="w50 columna100">
                                        <Row>
                                            <Col md={4} xl={4}>Clientes</Col>
                                            <Col>
                                                <Space direction="horizontal" style={{ marginBottom: "7px" }}>
                                                    <Input
                                                        value={searchIntentos}
                                                        style={{ width: "100%" }}
                                                        placeholder="input search text"
                                                        onChange={(ev) => {
                                                            setSearchIntentos(ev.target.value)
                                                        }}
                                                        type={"number"}
                                                        allowClear
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4} xl={4}>Ultima Firma</Col>
                                            <Col>
                                                <Space direction="horizontal" style={{ marginBottom: "7px" }}>
                                                    <RangePicker
                                                        value={searchUltIntento}
                                                        format="DD-MM-YYYY"
                                                        onChange={arrDesdeHasta => {
                                                            setSearchUltIntento(arrDesdeHasta);
                                                        }}
                                                        ranges={getDateRanges()}
                                                        style={{ width: "100%" }}
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xs={24} style={{ textAlign: "right" }}>
                                        <Button loading={isLoading} onClick={() =>{  setPaginaActual(1) ;buscar() }}  style={{ marginRight: "5px" }}><SearchOutlined />Buscar</Button>
                                        <Button  onClick={() => limpiarDatos() } ><DeleteOutlined />Limpiar</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </>


                        <Card title="Lista" className="main-flatCard" style={{ width: "100%", overflow: "auto" }}>
                            {isLoading && <Spin size="large"/>}
                            { !isLoading && <table style={{ minWidth: "600px", borderSpacing: "20px" }} className="">
                                <thead>
                                    <tr className="cabecera">
                                        <td className="w2" style={{ verticalAlign: "middle !important" }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <div style={{ flex: "1"}}>
                                                    Colaborador
                                                    <SortComponent sortColumn="nombre_colaborador"/>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="w1">
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <div style={{ flex: "1" }}>IP</div>
                                            </div>
                                        </td>
                                        <td className="w2">
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <div style={{ flex: "1" }}>
                                                    Clientes
                                                    <SortComponent sortColumn="intentos"/>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="w1">
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <div style={{ flex: "1" }}>Ultima Firma</div>
                                            </div>
                                        </td>
                                        <td className="w1">
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <div style={{ flex: "1" }}>Acción</div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        signatures && signatures.length > 0 && signatures.map((_signature, i) => {

                                            return (
                                                <tr className="fila" key={_signature._id}>
                                                    <td>
                                                        {_signature.nombre_colaborador.toUpperCase()}
                                                    </td>
                                                    <td>
                                                        {_signature.ip}
                                                    </td>
                                                    <td>
                                                        {_signature.intentos}
                                                    </td>
                                                    <td>
                                                        {_signature.ultimo_intento.str}
                                                    </td>
                                                    <td>

                                                        <Button 
                                                            type="primary"
                                                            onClick={()=> {
                                                                setConfirmModal(true);
                                                                setModalColaborador(_signature);
                                                                }
                                                            }
                                                        >
                                                            Validar IP
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>}

                            <div style={{ textAlign: "right", width: "100%", marginTop: "20px" }}>
                                <Pagination
                                    // defaultPageSize={2} 
                                    total={totalRegistros}
                                    current={paginaActual}
                                    onChange={(pagina, registros) => {
                                        
                                        setPaginaActual(pagina)
                                        setRegistrosPorPagina(registros)
                                    }}
                                />
                            </div>
                            {/* Modal confimacion validar ip */}
                            <ModalConfirm/>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}